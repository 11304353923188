<template>
  <Page :title="'人民调解'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">人民调解</h1>
          <p class="desc">
            人民调解是指在人民调解组织的主持下，以国家法律、法规、规章和社会公德为依据，对民间纠纷当事人进行说服教育、规劝疏导，促使纠纷各方当事人互谅互让，平等协商，自愿达成协议，消除纷争的一种群众自治活动。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/mediatehandle" target="_blank">在线办理</router-link></a-button>
            <a-button><router-link to="/mediate" target="_blank">预约办理</router-link></a-button>
          </div>
          <span class="business-banner-head-icon rmtj-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>人民调解申请指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
          <a-tab-pane key="condition" tab="什么是人民调解">
            <div class="ov_text">
              <div class="law_methods_main_text">
                <h4>受理条件及范围</h4>
                <p>受理条件：</p>
                <p>(1)有明确的被申请调解人;</p>
                <p>(2)有具体的要求;</p>
                <p><p>(3)有提出调解申请的事实依据;</p>
                <p>(4)申请调解的纠纷必须属于人民调解委员会受理范围，并原则上由该人民调解组织受理。</p>
                <h4>受理范围：</h4>
                <p>人民调解委员会调解的民间纠纷，包括发生在公民与公民之间、公民与法人和其他社会组织之间涉及民事权利义务争议的各种纠纷。主要是以下几种民间纠纷:</p>
                <p>(1)公民与公民之间的纠纷。一般是是指发生在家庭成员、邻里、同事、居民、村民等社会成员之间，因合法权益受到侵犯或者发生争议而引起的纠纷。</p>
                <p>(2)公民与法人和其他社会组织之间涉及民事权利义务关系的纠纷。</p>
                <p>(3)企业职工与所在企业之间，因企业转制、租赁、兼并、破产、收购、转让、或者因企业拖欠职工工资、医疗费等发生的纠纷。</p>
                <p>(4)城市居民与城市市政管理组织、施工单位，企业事业单位等因城市街道市政建设，危改房屋改造等引发的纠纷。</p>
                <h4>下列纠纷人民调解不予受理:</h4>
                <p>（1）法律、法规规定只能由专门机关管辖处理的，或者法律、法规禁止采用民间调解方式解决的。</p>
                <p>（2）包括:①已构成犯罪的刑事案件②构成违反《治安管理处罚条例》的③具体行政行为引发的，例如工商管理引发的纠纷、税务纠纷等;</p>
                <p>（3）人民法院、公安机关或者其他行政机关已经受理或者解决的;</p>
                <p>（4）一方当事人不同意调解的。</p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="什么是人民调解委员会">
            <div class="ov_text">
              <h4>什么是人民调解委员会?</h4>
              <p>人民调解委员会是依法设立的调解民间纠纷的群众性组织，在司法局的指导下开展工作。人民调解委员会一般由3至9名成员组成。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="人民调解有何好处">
            <div class="ov_text">
              <h4>人民调解协议书法律效力</h4>
              <p>人民调解协议书是双方当事人就如何妥善解决矛盾纠纷自愿签订的法律文书，具有法律约束力，当事人应自觉履行协议内容。当事人如果认为有必要，可向法院申请司法确认，经人民法院确认有效后的调解协议具有强制执行力。</p>
              <h4>人民调解有何好处</h4>
              <p>人民调解具有扎根基层、深入群众、便民惠民的特征，能够最大限度的将矛盾纠纷化解在基层， 解决在萌芽状态，能有效防止事态恶化、维护当事人合法权益、保障社会和谐稳定；</p>
              <p>人民调解委员会作为第三方和中间方，通过单独约谈，既能如实公证的传达双方当事人的意愿和诉求， 又能避免双方当事人在直接对话时可能引发的紧张和冲突；</p>
              <p>可以节省时间、精力以及因诉讼而产生的费用；</p>
              <p>由于调解协议是双方当事人经协商一致所达成的，所以履行起来会又好又快。</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="权利义务及收费标准">
            <div class="ov_text">
              <h4>当事人的权利和义务：</h4>
              <p>《人民调解法》第二十三条规定:当事人在人民调解活动中享有下列权利:</p>
              <p>(一)选择或者接受人民调解员;</p>
              <p>(二)接受调解、拒绝调解或者要求终止调解;</p>
              <p>(三)要求调解公开进行或者不公开进行;</p>
              <p>(四)自主表达意愿、自愿达成调解协议。</p>
              <p>《人民调解法》第二十四条规定:当事人在人民调解活动中履行下列义务:</p>
              <p>(一)如实陈述纠纷事实;</p>
              <p>(二)遵守调解现场秩序，尊重人民调解员;</p>
              <p>(三)尊重对方当事人行使权利。</p>
              <h4> 收费标准</h4>
              <p>《人民调解法》第4条规定:人民调解委员会调解民间纠纷，不收取任何费用。</p>
            </div>
          </a-tab-pane>
        </a-tabs>
        </div>
        <IntelligentConsult :value="mediatetUrl"></IntelligentConsult>
        <div class="mt-5 overflow-hidden tab">
          <div class="col-lg-6 col-md-6 col-xs-6 float-left pl-0">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="调解动态">
                <div  class="tab-pane">
                    <Collection  name="post"   :query="{ target: '0176141c589f8a8380377603faf907f6' }"
                      v-slot="{ collection }"  :limit=9 >
                    <Article :refObj="collection"></Article>
                    </collection>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="col-lg-6 col-md-6 col-xs-6 float-right p-0 pl-4">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1" tab="办事表格下载">
                <div  class="tab-pane">
              <Collection  name="post" :query="{ target: '0176995819778a838284769932e50102' }"
                v-slot="{ collection }"  :limit=9 >
              <Article :refObj="collection"></Article>
              </collection>
              </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="人民调解机构">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_name: providerQuery.provider_name,
                  provider_type: '人民调解机构'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="调解员">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: '人民调解人员'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import Article from '../../components/base/Article.vue'
import IntelligentConsult from '@/components/business/IntelligentConsult'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    Article,
    IntelligentConsult
  },
  data () {
    return {
      mediatetUrl: '/mediate',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    }
  }
}
</script>
